.coming-soon-container {
  display: flex;
  justify-content: center;
  height: calc(100vh - 80px);
  text-align: center;
}

.coming-soon {
  font-size: 48px;
  color: #000000;
  margin-top: calc(50vh - 128px);
}
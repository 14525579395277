.warranty-container {
  padding: 20px;
  padding-top: 0;
  max-width: 1200px;
  margin: 0 auto;
}

.warranty-title {
  text-align: center;
  font-size: 36px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.warranty-paragraph {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 15px;
  text-align: left;
}
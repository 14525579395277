.mobile-navbar {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.navbar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left {
  display: inline-block;
  vertical-align: top;
  position: relative;
  text-align: left;
}

.logo {
  display: inline-block;
  vertical-align: top;
  top: 0px;
}

.logo a {
  text-decoration: none;
  font-size: 24px;
}

.hamburger-container {
  display: inline-block;
  margin-top: 25px;
  margin-left: 20px;
  margin-right: 20px;
}

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.hamburger .line {
  width: 100%;
  background-color: #000;
  height: 2px;
  transition: all 0.3s ease;
}

.hamburger .line.open:nth-child(1) {
  transform: rotate(45deg) translate(9px, 9px);
}

.hamburger .line.open:nth-child(2) {
  opacity: 0;
}

.hamburger .line.open:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

.mcart svg {
  width: 40px;
  height: 40px;
  text-decoration: none;
  margin-left: auto;
  color: #000000;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 80px;
  left: -100%;
  width: 100%;
  background-color: #fff;
  color: #000;
  transition: left 0.3s ease;
  overflow-y: scroll;
  z-index: 10;
  text-align: left;
}

.dropdown-menu.open {
  left: 0;
}

.dropdown-menu ul {
  list-style-type: none;
  padding: 0;
  padding-top: 15px;
  margin: 0 20px;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.dropdown-header {
  padding: 15px 0px;
  color: #000;
  text-decoration: none;
}

.dropdown-header:hover {
  color: #333;
}

.dropdown-section {
  margin-top: 15px;
  padding-left: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-left: 1px solid #ddd;
}

.dropdown-menu li {
  padding-bottom: 15px;
}

.dropdown-menu li a {
  color: #000;
  text-decoration: none;
}

.dropdown-menu li a:hover {
  color: #333;
}

.last-li {
  padding-bottom: 0 !important;
}

.dropdown-log-in {
  color: #000;
  text-decoration: none;
  margin-left: 20px;
}

.dropdown-space {
  height: 15px;
}
.item-detail {
  padding: 0;
  text-align: center;
  vertical-align: top;
}

.item-image {
  width: 100%;
  height: auto;
  margin: 20px 0;
}

.item-image {
  height: min(80vh, calc(100vw - 40px));
  width: auto;
  margin: 0;
  display: inline-block;
}

.add-to-cart-button {
  background-color: #7627df;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 24px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
  width: 100%;
}

.add-to-cart-button:hover {
  background-color: #3e138f;
}

.ispecs {
  position: relative;
  width: min(60vh, calc(100% - 40x));
  height: auto;
  display: inline-block;
  text-align: left;
  margin-left: 0px;
  vertical-align:top;
  padding: 20px;
}

.ispec {
  width: 100%;
}

.ispec svg {
  margin-right: 10px;
  margin-left: 10px;
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: top;
  margin-top: 0;
  margin-bottom: 6px;
}

.ispec p {
  vertical-align: top;
  font-size: 16px;
  height: 16x;
  display: inline-block;
  margin: 0;
  margin-bottom: 4px;
  width: calc(100% - 44px);
}

.ititle {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 48px;
}

.iprice {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 28px;
}

#components-different p {
  font-style: italic;
  margin-bottom: 0;
}

.ispec-label {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 0;
}

.ispec-label p {
  margin: 0;
  margin-bottom: 4px;
}

.spec-list {
  height: 50vh !important;
  overflow-y: scroll;
  margin-bottom: 10px;
}

.left-of-image {
  display: inline-block;
  vertical-align: top;
  width: 10vh;
  margin-top: 40px;
  padding: 20px;
  padding-top: 0;
  padding-bottom: 0;
}

.item-image-white {
  width: 10vh;
  padding: 4px;
  height: auto;
  border: 2px solid #7627df;
  border-radius: 10px;
  cursor: pointer;
}

.item-image-black {
  width: 10vh;
  padding: 4px;
  height: auto;
  border: 2px solid #000;
  border-radius: 10px;
  cursor: pointer;
}
.ccart {
  padding: 20px;
  display: inline-block;
  vertical-align: top;
  width: max(50%, 266px);
}

.mccart {
  width: max(80%, 266px);
}

.ccart-title {
  border-bottom: 1px solid #ddd;
  padding-bottom: 0px;
  height: auto;
  display: flex;
  justify-content: space-between;
}

.ccart-title-small {
  border-bottom: 1px solid #ddd;
  padding-bottom: 0px;
  height: auto;
}

.cart-item-count {
  font-weight: normal;
} 

.ccart ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.ccart li {
  border-bottom: 1px solid #ddd;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}

.ccart h3 {
  margin: 0;
  display: inline-block;
}

.ccart p {
  margin: 5px 0;
}

.cspec-list {
  height: auto !important;
  overflow: hidden;
}

.ctitle {
  display: inline-block;
  font-size: 28px;
}

.cprice {
  display: inline-block;
  font-size: 24px;
}

.cimage {
  width: 100px;
  margin-right: 30px;
  float: left;
}

.cright-details {
  display: inline-block;
  width: auto;
  vertical-align: top;
  margin-left: 40px;
  height: 100%;
}

.cright-details-small {
  display: flex;
  width: 100%;
  vertical-align: top;
  margin-left: 0px;
  height: auto;
  justify-content: space-between;
}

.cspecs {
  padding: 0;
}

.cspec {
  font-size: 12px;
}

.cspec p {
  margin: 0;      
  margin-left: 2em;
  text-indent: -2em;
}

.cright-details svg {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.cright-details div {
  text-align: right;
  margin-bottom: 10px;
}

.cright-details-small svg {
  display: inline-block;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.cright-details-small div {
  display: inline-block;
  text-align: right;
  margin-bottom: 10px;
}

.cright-details-small h1 {
  margin: 0;
}

.cplus {
  margin-right: 4px;
}

.ccheckout {
  display: inline-block;
  vertical-align: top;
  padding: 20px;
  margin: 20px;
  border-radius: 5%;
  background-color: #ddd;
  width: max(20vw, 266px);
}

.mccheckout {
  display: block;
  margin: 20px auto;
  margin-top: 0;
  width: max(50%, 266px);
}

.ccheckout h3 {
  font-size: 18px;
  text-align: left;
  font-weight: normal;
  margin: 0;
}

.ccheckout p {
  font-size: 18px;
  margin: 0;
}

.csubtotal {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.csubtotal h3 {
  font-size: 24px !important;
  font-weight: bold;
}

.csubtotal p {
  font-size: 24px !important;
}

.ctaxes {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.cshipping {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.ccoupon {
  display: flex;
  justify-content: space-between;
}

.ccoupon input {
  width: 100%;
  margin-left: 20px;
  text-align: right;
  font-size: 18px;
  font-family: 'Outfit';
}

.ccoupon input:focus {
  outline: none;
}

.ccheckout-button {
  background-color: #7627df;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 24px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  width: 100%;
}

.ccheckout-button:hover {
  background-color: #3e138f;
}

.ccheckout-button:disabled {
  background-color: #3e138f;
}

.apply-coupon {
  text-align: right;
  font-size: 14px !important;
  font-style: italic;
}

.coupon-code {
  margin-bottom: 4px !important;
}

.your-cart {
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.your-cart-small {
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.cart-item-count {
  margin-top: 20px;
  margin-bottom: 20px;
}

.csummary {
  margin-top: 0px;
  margin-bottom: 20px;
}

.empty-cart {
  margin: 0 !important;
  margin-top: 20px !important;
}

.cleft-details {
  display: inline-block;
}

@media screen and (min-width: 850px) {
  .mccheckout {
    border-radius: 5% / 6%;
  }
}

@media screen and (min-width: 925px) {
  .mccheckout {
    border-radius: 5% / 7%;
  }
}

@media screen and (min-width: 1000px) {
  .mccheckout {
    border-radius: 5% / 8%;
  }
}

@media screen and (min-width: 1075px) {
  .mccheckout {
    border-radius: 5% / 9%;
  }
}

@media screen and (min-width: 1150px) {
  .mccheckout {
    border-radius: 5% / 10%;
  }
}

@media screen and (min-width: 1225px) {
  .mccheckout {
    border-radius: 5% / 11%;
  }
}

@media screen and (max-width: 850px) {
  .mccart {
    width: max(50%, 266px);
  }
}
/* BuildOptions.css */
.build-options-container {
  width: calc(100vw - 70px);
  margin: 0 auto;
  padding: 20px;
  padding-top: 10px;
  position: relative;
}

.card {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);;
  border-radius: 15px;
  overflow: hidden;
  text-align: center;
  padding: 10px;
  margin: 0 15px; /* Add horizontal margin to separate the cards */
  transition: box-shadow 0.3s ease;
  position: relative;
}

.card:hover {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2); /* Slightly stronger shadow on hover */
}

.card-image {
  width: 100%;
  height: auto;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.card-content {
  padding: 10px;
}

.card-content h3 {
  font-size: 1.5em;
  margin: 10px 0;
}

.card-content p {
  font-size: 1em;
  margin: 10px 0;
}

.buy-now-button {
  background-color: #7627df;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  margin: 10px;
}

.buy-now-button:hover {
  background-color: #3e138f;
  cursor: pointer;
}

.slick-next:before, .slick-prev:before {
  display: none; /* Hide the default arrows */
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-track {
  display: flex;
  align-items: center;
}

.slick-slide {
  display: flex;
  justify-content: center;
  height: auto;
}

.slick-dots {
  position: relative;
  margin-top: -10px;
  margin-bottom: 20px;
}

.color-dots {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  gap: 5px;
}

.color-dot.black {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  background-color: black;
}

.color-dot.white {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  cursor: pointer;
  background-color: white;
  border: 1px solid #000;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  /* padding: 10px 20px; */
}

.navbar-logo {
  height: 80px;
}
  
.navbar-logo a {
  color: #000000;
  text-decoration: none;
}

.navbar-menu {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-item {
  position: relative;
  text-decoration: none;
  color: #000000;
}

.navbar-item a,
.dropbtn {
  color: #000000;
  text-decoration: none;
  padding: 26px 15px;
  margin: 0 0;
  font-size: 20px;
  width: 130px;
  height: 28px;
  display: block;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #ffffff;
}

.logo {
  height: 80px;
  width: 80px;
}

#first-item {
  margin-left: 40px;
}

.cart svg {
  width: 40px;
  height: 40px;
  text-decoration: none;
  margin-left: auto;
  color: #000000;
  cursor: pointer;
}

.navbar-right {
  display: flex;
  align-items: center;
  height: 80px;
}

.navbar-left {
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.cart {
  margin-right: 20px;
}


.cart-icon {
  color: #fff;
  font-size: 24px;
  text-decoration: none;
  position: relative;
}

.cart-count {
  position: absolute;
  top: 10px;
  right: 7px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 3px 3px;
  font-size: 14px;
}

.cart-count {
  width: 18px;
  height: 18px;
}
.spec {
  width: 100%;
  height: auto;
  text-align: left;
  display: inline-block;
}

.spec p {
  height: 20x;
  display: inline-block;
  margin: 0;
  font-size: 16px;
  margin-bottom: 4px;
  width: calc(100% - 26px);
}

.spec svg {
  margin-right: 6px;
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: top;
  margin-top: 0;
}

.price {
  font-size: 20px !important;
  margin: 0 !important;
  padding-left: 10px;
  text-align: left;
  font-weight: bold;
}